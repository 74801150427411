.animated-background-navbar {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -10000;
    background-color: var(--bs-secondary);
}

a {
    color: var(--bs-spring-text);
}

.text-indigo {
    color: var(--bs-spring-text);
}

.navbar .nav-link.text-indigo,
.navbar .nav-link.text-indigo:hover,
.navbar .nav-item.dropdown .dropdown-menu .dropdown-item.text-indigo,
.navbar .nav-item.dropdown .dropdown-menu .dropdown-item.text-indigo:hover,
.navbar .nav-item.dropdown .dropdown-toggle.text-indigo,
.navbar .nav-item.dropdown .dropdown-toggle.text-indigo:hover {
    color: var(--bs-spring-text) !important;
}

.navbar-scroll .dropdown-menu {
    transition: opacity 1s ease, visibility 1s ease, transform 1s ease !important;
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
}

.navbar-scroll .dropdown-menu.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.nav-link, .dropdown-item {
    transition: color 0.3s ease;
}
