.hover-popup {
  position: absolute;
  background-color: var(--bs-tertiary);
  border: 1px solid var(--bs-tertiary);
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  pointer-events: none;
}
  
tr:hover .hover-popup {
  display: block;
}

.szemely-list tbody tr {
  position: relative;
}
  