.level-exercise-container {
    padding: 2rem 0;
  }
  
  .exercise-wrapper {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }
  
  
  .exercise-content {
    margin-bottom: 1.5rem;
  }

  .create-button-wrapper {
    text-align: center;
    margin-top: 2rem;
  }
  
  .btn-info {
    display: flex;
    align-items: center;
  }
  