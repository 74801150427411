.course-list-container {
  padding: 20px;
  background-color: var(--bs-light);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.course-list-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  color: var(--bs-dark);
}

.course-card {
  background-color: var(--bs-light);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.course-card:hover {
  transform: translateY(-10px);
}

.course-link {
  font-weight: bold;
  color: var(--bs-primary);
  text-decoration: none;
}

.course-link:hover {
  color: var(--bs-secondary);
}

.course-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.btn {
  display: flex;
  align-items: center;
}
