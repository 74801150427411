// Alapszínek - Élénk Tavasz
$primary: #45616c;    // Kékes (Tavaszi kék)
$secondary: #9fc4d6; // Barackos narancs
$tertiary: #e4e6e6;  // Tavaszi sárga
$accent: #6bcfa3;    // Világos zöld
$warning: #f2e47d;   // Napsárga (Bootstrap Warning)
$success: #c2e49b;   // Zöld (Bootstrap Success)
$error: #d96e6e;     // Pirosas (Bootstrap Danger)

// További színek - Élénk Tavasz
$korall: #ff7f50;  // Korallszín
$vilagoskek: #87ceeb;       // Világoskék
$lazac: #dceaa4;     // Lazacszín
$vilagoszold: #90ee90;    // Világos zöldessárga
$elenkrozsa: #ff69b4;      // Élénk rózsaszín

// Szövegek és hátterek
$text-spring: #293933;
$bg-light: #fefae0;    // Krémes árnyalat
$text-dark: #343a40;  // Sötétszürke (Bootstrap Dark)
$text-light: #ffffff; // Fehér

// Színpaletta
$palette: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "accent": $accent,
  "warning": $warning,
  "success": $success,
  "error": $error,
  "korall": $korall,
  "vilagoskek": $vilagoskek,
  "lazac": $lazac,
  "vilagoszold": $vilagoszold,
  "elenkrozsa": $elenkrozsa,
  "spring-text": $text-spring,
  "bg-light": $bg-light
);

// Bootstrap testreszabása
:root {
  --bs-primary: #{$primary};
  --bs-secondary: #{$secondary};
  --bs-success: #{$success};
  --bs-accent: #{$accent};
  --bs-tertiary: #{$tertiary};
  --bs-warning: #{$warning};
  --bs-danger: #{$error};
  --bs-korall: #{$korall};
  --bs-vilagoskek: #{$vilagoskek};
  --bs-lazac: #{$lazac};
  --bs-vilagoszold: #{$vilagoszold};
  --bs-elenkrozsa: #{$elenkrozsa};
  --bs-dark: #{$text-dark};
  --bs-spring-text: #{$text-spring};
  --bs-bg-light: #{$bg-light};
}

// Globális stílusok az egész alkalmazásra
body {
  background-color: var(--bs-bg-light);
  color: var(--bs-spring-text);
}

// Alapértelmezett színek a HTML elemekhez
h1, h2, h3, h4, h5, h6, p, span, div, a, button {
  color: var(--bs-spring-text);
}

table {
  th, td {
    color: var(--bs-spring-text);
  }
}

// Bootstrap importálása
@import "../node_modules/bootstrap/scss/bootstrap";
