.scale-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

.circle-wrapper {
    position: relative;
    margin: 0 5px;
}

.tooltip {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap; /* Megakadályozza a sortörést */
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
}


.tooltip.visible {
    visibility: visible;
    opacity: 1;
}
