.steps-container {
    display: flex;
    flex-direction: column; /* Keeps the steps stacked vertically */
    transition: opacity 0.5s ease-in-out;
    width: 100%;
    overflow-x: hidden; /* Ensure content doesn't overflow horizontally */
}

.step {
    width: 100%; /* Ensure each step takes the full width */
    display: none; /* Hide steps by default */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.step.active {
    display: flex; /* Show the active step */
    opacity: 1;
}

.modal-body {
    position: relative;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.button-container .btn {
    flex-grow: 1;
    max-width: 120px;
}
