.rating-scale {
  text-align: center;
  margin-bottom: 20px;
}

/* Új sor a kérdéshez és a gombhoz */
.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.question {
  font-size: 1.2em;
  margin: 0;
}

/* A kisebb gomb ikonokkal */
.toggle-button {
  padding: 4px 4px;
  font-size: 0.75em;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: var(--bs-accent); /* Világos zöld */
  color: var(--bs-dark);             /* Sötétebb türkiz a kontraszthoz */
  border: 1px solid var(--bs-secondary);
  border-radius: 20px;      /* Lekerekített forma */
  transition: background-color 0.3s, box-shadow 0.3s;
}
.tanfolyammal_elerheto {
  padding: 4px 10px;
  font-size: 0.6em;
  display: inline-flex;
  align-items: center;
  justify-content: center; /* Középre igazítás */
  gap: 2px;
  background-color: var(--bs-secondary); /* Világos zöld */
  color: var(--bs-dark);             /* Sötétebb türkiz a kontraszthoz */
  border-radius: 20px;      /* Lekerekített forma */
  transition: background-color 0.3s, box-shadow 0.3s;
}

.toggle-button:hover {
  background-color: var(--bs-secondary); /* Világosabb árnyalat hoverre */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Finom árnyék */
}

.scale-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.scale-circle {
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.3s;
  position: relative;
}

.scale-circle.active {
  transform: scale(1.1);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}

.scale-circle.disabled {
  cursor: not-allowed;
  background-color: rgba(128, 128, 128, 0.5);
}

.labels {
  display: flex;
  justify-content: space-between;
  margin: 0 10%;
}

.label {
  font-size: 0.9em;
}

.label.left {
  color: var(--bs-danger);
}

.label.center {
  color: var(--bs-light);
}

.label.right {
  color: rgba(0, 106, 255, 0.902);
}

.scale-section {
  margin-bottom: 8px;
}

.oldal {
  display: inline-block;             /* Inline-block for dynamic width */
  padding: 4px 8px;
  font-size: 0.65em;
  background-color: var(--bs-accent); /* Világos pasztell zöld */
  color: var(--bs-dark);             /* Sötétebb türkiz a kontraszthoz */
  border-radius: 16px;               /* Chip-szerű lekerekítés */
  border: 1px solid var(--bs-secondary); /* Halvány pasztell szegély */
  text-align: center;                /* Szöveg középre igazítása */
  margin: 5px 0;
  font-weight: 500;                  /* Enyhén vastagabb szöveg */
  white-space: nowrap;               /* Ne törje a szöveget több sorba */
  max-width: fit-content;            /* Pontosan a szöveghez igazítja a szélességet */
}

.scale-container {
  display: flex;
  justify-content: center;  /* Középre igazítás */
  align-items: center;
  gap: 10px;
}
