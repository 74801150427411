/* Felső kategória navigáció */
.category-navigation {
  display: flex;
  justify-content: flex-start; /* Balra igazítás, hogy könnyebb legyen görgetni */
  margin-bottom: 10px;
  overflow-x: auto; /* Engedélyezi a vízszintes görgetést */
  white-space: nowrap; /* Megakadályozza a tördelést */
  -webkit-overflow-scrolling: touch; /* Folyékony görgetés iOS eszközön */
}

/* Görgetősáv stílusok eltüntetése */
.category-navigation::-webkit-scrollbar {
  height: 0px; /* Görgetősáv magassága */
}

.category-navigation::-webkit-scrollbar-thumb {
  background-color: var(--bs-light);
  border-radius: 5px;
}

.category-navigation::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Kategória gombok */
.category-button {
  margin: 0 5px;
  padding: 8px 12px;
  background-color: var(--bs-light);
  border: 1px solid var(--bs-secondary);
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 0 0 auto; /* Ne zsugorodjon össze */
}

.category-button.active {
  background-color: var(--bs-primary);
  color: var(--bs-light);
  border: 1px solid var(--bs-secondary);
}

.category-button:hover {
  background-color: var(--bs-secondary);
}

/* Kártya konténer */
.aoszempont-container {
  position: relative;
}

/* Nyilak */
.navigation-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 30px;
}

.arrow {
  margin: 0 10px;
}

.card-index {
  font-size: 16px;
  font-weight: bold;
  color: var(--bs-dark);
}

.arrow:hover {
  transform: scale(1.1);
  background-color: var(--bs-secondary);
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.double-arrow {
  right: 10px;
  fill: var(--bs-korall);
  position: relative; /* Biztosítja, hogy a `top` tulajdonság működjön */
  top: -1.5px;
}

.arrow:disabled {
  background: var(--bs-light);
  cursor: not-allowed;
}

.aoszempont-carousel {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.aoszempont-kartya {
  flex: 0 0 100%;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  background: var(--bs-light);
  border-radius: 8px;
  max-height: 80%;
  overflow-y: auto;
  transition: transform 0.6s ease-in-out; /* Smooth váltás */
}

/* Mobil nézetre optimalizált stílusok */
@media (max-width: 768px) {
  .aoszempont-carousel {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: auto; /* A magasság automatikusan igazodik a tartalomhoz */
  }


  .aoszempont-kartya {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    background: var(--bs-light);
    border: 0px;
    padding: 0px;

    /* Mobilon a magasság lehet például 90% */
    max-height: 40%;
    overflow-y: auto; /* Görgethetővé válik, ha szükséges */
  }
}
