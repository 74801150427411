.szemely-kepek img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.szemely-kepek img:hover {
    transform: scale(1.1);
}

.szemely-kepek img.fullscreen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    z-index: 1000;
    object-fit: contain;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.9);
}

  /* Flexbox grid for image layout */
  .image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; /* Space between images */
    justify-content: center;
    overflow-x: hidden; /* Hide horizontal overflow */
  }

  .image-grid img {
    max-width: 100px; /* Max width for smaller screens */
    flex: 1 1 calc(33.333% - 8px); /* Three images per row by default */
    box-sizing: border-box;
  }

  /* Tablet and larger screens: 3 images per row */
  @media (max-width: 1024px) {
    .image-grid img {
      flex: 1 1 calc(33.333% - 8px); /* 3 images per row */
      max-width: 100px;
    }
  }

  /* Laptop and larger screens: Limit image size to 300px */
  @media (min-width: 1025px) {
    .image-grid img {
      max-width: 300px;
    }
  }

  /* For very small screens, 100px width for each image, without horizontal scroll */
  @media (max-width: 480px) {
    .image-grid img {
      flex: 1 1 calc(33.333% - 8px); /* Ensure 3 images fit in the row */
      max-width: 100px;
    }
  }


  .image-container {
    position: relative;
    display: inline-block;
  }

  .image-container img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  .delete-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease, transform 0.2s ease, background-color 0.2s ease;
    border-radius: 50%; /* Kör alakú forma */
    background-color: 'var(--bs-light)';
  }

  .delete-icon svg {
    width: 16px;
    height: 16px;
  }

  .delete-icon:hover {
    opacity: 1; /* Erősebb, de nem túlzott kiemelés hoverre */
    transform: scale(1.5); /* Finom nagyítás */
    background-color: 'var(--bs-light)';
  }