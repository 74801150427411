@keyframes changeBackgroundColorKezdolap {
    0% { background-color: var(--bs-accent); } /* Kezdeti szín */
    50% { background-color: var(--bs-accent); } /* Középső szín */
    100% { background-color: var(--bs-accent); } /* Végső szín */
}

@keyframes changeBackgroundColorInfo {
    0% { background-color: var(--bs-tertiary); } /* Kezdeti szín */
    50% { background-color: var(--bs-tertiary); } /* Középső szín */
    100% { background-color: var(--bs-tertiary); } /* Végső szín */
}

.animated-background-kezdolap {
    color: var(--bs-spring-text);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    animation: changeBackgroundColorKezdolap 13s linear infinite;
}

.animated-background-info {
    color: var(--bs-spring-text);
    z-index: 10;
    animation: changeBackgroundColorInfo 14s linear infinite;
}

.info-icon-container {
    display: flex;
    justify-content: flex-end; /* Aligns the icon to the right */
}

.myheader {
    letter-spacing: 0.15em; /* Itt állíthatod a betűk közötti távolságot */
}

* {
    color: var(--bs-spring-text); /* Az alap szín a sötétszürke */
}

h5 {
    color: var(--bs-spring-text); /* A h5 szöveg színe is sötétszürke */
}
