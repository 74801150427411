.finish-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  }
  
  .animated-alert {
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  